import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { AccessDeviceModel } from 'core/domain/access/models';
import { GetAccessDevicesListByProjectId } from 'core/domain/access/repository/getAccessDevicesListByProjectId';
import { useMessage } from 'hooks/useMessage';
import { HotelAccessDevicesListColumns, HotelAccessDevicesListColumnsLiteralsModel } from './AccessDevicesListColumns';
import { OpenHotelAccessDeviceModalLiteralsModel } from './OpenHotelAccessDeviceModal';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getHotelAccessDevicesListWithPaginationPath } from 'components/pages/App/routes/access/config';
import { OpenAccessDevice } from 'core/domain/access/repository/openAccessDevice';

const DEFAULT_TOTAL_PAGES_VALUE = 1;

export const useHotelAccessDevicesList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [accessDevicesList, setAccessDevicesList] = useState<AccessDeviceModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAccessModalVisible, setOpenAccessModalVisible] = useState<boolean>(false);
  const [currentAccessDevice, setCurrentAccessDevice] = useState<AccessDeviceModel | null>(null);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES_VALUE);
  const [pageCurrent, setPageCurrent] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState(Number(TABLE_PAGINATION_SIZE_DEFAULT));

  const columnsLiterals: HotelAccessDevicesListColumnsLiteralsModel = {
    nameTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_NAME_TITLE'),
    roomTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_ROOM_TITLE'),
    areaTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_AREA_TITLE'),
    batteryTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_BATTERY_LEVEL_TITLE'),
    actionsTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_ACTIONS_TITLE'),
    openAccessDeviceText: t('_ACCESS_DEVICES_LIST_OPEN_ACCESS_TEXT'),
  };

  const openAccessDeviceModalLiterals: OpenHotelAccessDeviceModalLiteralsModel = {
    title: t('_OPEN_ACCESS_DEVICE_MODAL_TITLE', { name: currentAccessDevice?.name || '' }),
    text: t('_OPEN_ACCESS_DEVICE_MODAL_TEXT'),
    description: t('_OPEN_ACCESS_DEVICE_MODAL_DESCRIPTION'),
    primaryButtonText: t('_ACCESS_DEVICES_OPEN_MAIN_BUTTON_MODAL'),
    secondaryButtonText: t('cancel'),
  };

  const getAccessDevicesList = async ({ page, size }: { page: number; size: number }) => {
    try {
      const { data, meta } = await GetAccessDevicesListByProjectId({ host, token, projectId, page, size });
      setAccessDevicesList(data);
      setTotalPages(meta.totalPages);
    } catch (error) {
      setMessageError({ description: t('_ACCESS_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const onOpenAccessDeviceModal = (selectedAccessDevice: AccessDeviceModel) => {
    setOpenAccessModalVisible(true);
    setCurrentAccessDevice(selectedAccessDevice);
  };

  const onCloseAccessDeviceModal = () => {
    setOpenAccessModalVisible(false);
    setCurrentAccessDevice(null);
  };

  const onOpenAccessDevice = async () => {
    try {
      if (!!currentAccessDevice) {
        const { accessSensorId } = currentAccessDevice;
        await OpenAccessDevice({ host, token, accessSensorId });
        onCloseAccessDeviceModal();
      }
    } catch (error) {
      setMessageError({ description: t('_ACCESS_DEVICES_LIST_OPEN_ACCESS_DEVICE_ERROR_MESSAGE') });
    }
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setPageCurrent(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  const onChangePage = (page: number, pageSize?: number) => {
    setPageCurrent(page);
    setPageSize(pageSize!);
    history.push(getHotelAccessDevicesListWithPaginationPath({ clientId, projectId, page: String(page), size: String(pageSize!) }));
  };

  useEffect(() => {
    setLoading(true);
    getAccessDevicesList({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  const columns = HotelAccessDevicesListColumns({ literals: columnsLiterals, onOpenAccessDevice: onOpenAccessDeviceModal });

  const openAccessDeviceModalOptions = {
    openAccessDeviceModalLiterals,
    onCloseAccessDeviceModal,
    onOpenAccessDevice,
    openAccessModalVisible,
  };

  return {
    accessDevicesList,
    pageCurrent,
    pageSize,
    totalPages,
    columns,
    openAccessDeviceModalOptions,
    onChangePage,
    loadingAccessDevices: loading,
  };
};
