import { TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import {
  AccessDeviceAssetModel,
  AccessDeviceAssetPayload,
  AccessDeviceLinksModel,
  AccessDeviceLinksPayload,
  AccessDeviceMetaModel,
  AccessDeviceMetaPayload,
  AccessDeviceModel,
  AccessDevicePayload,
  AccessDevicesListResponseModel,
  AccessDevicesListResponsePayload,
} from '../models';

const payloadToAccessDeviceAsset = ({
  alias,
  asset_id,
  block_number,
  city,
  country,
  door,
  flat,
  province,
  staircase,
  street_name,
  street_type,
  type,
  zip_code,
}: AccessDeviceAssetPayload): AccessDeviceAssetModel => {
  return {
    alias: alias ?? null,
    assetId: asset_id ?? '',
    blockNumber: block_number ?? '',
    city: city ?? '',
    country: country ?? '',
    door: door ?? null,
    flat: flat ?? null,
    province: province ?? '',
    staircase: staircase ?? null,
    streetName: street_name ?? '',
    streetType: street_type ?? '',
    type: type ?? '',
    zipCode: zip_code ?? '',
  };
};

const payloadToAccessDevice = ({
  asset,
  battery_level,
  device_type,
  name,
  room,
  shared,
  access_sensor_id,
}: AccessDevicePayload): AccessDeviceModel => {
  const transformedAsset = !!asset ? payloadToAccessDeviceAsset(asset) : null;

  return {
    asset: transformedAsset,
    batteryLevel: battery_level ?? null,
    deviceType: device_type ?? null,
    name: name ?? null,
    room: room ?? null,
    shared: shared ?? false,
    accessSensorId: access_sensor_id ?? '',
  };
};

const payloadToAccessDeviceMeta = ({ all_results, page_size, results, total_pages }: AccessDeviceMetaPayload): AccessDeviceMetaModel => {
  return {
    allResults: all_results ?? 0,
    pageSize: page_size ?? TABLE_PAGINATION_SIZE_DEFAULT,
    results: results ?? 0,
    totalPages: total_pages ?? 1,
  };
};

const payloadToAccessDeviceLinks = ({ first, last, next, prev, self }: AccessDeviceLinksPayload): AccessDeviceLinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

export const payloadToAccessDevicesListByProjectIdResponse = ({
  data,
  meta,
  links,
}: AccessDevicesListResponsePayload): AccessDevicesListResponseModel => {
  const transformedData: AccessDeviceModel[] = data.map((accessDevice) => payloadToAccessDevice(accessDevice)) ?? [];
  const transformedMeta: AccessDeviceMetaModel = payloadToAccessDeviceMeta(meta);
  const transformedLinks: AccessDeviceLinksModel = payloadToAccessDeviceLinks(links);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: transformedLinks,
  };
};
