export enum LocalStorageAccessKeys {
  CLIENT_ID = 'ACCESS_CLIENT_ID',
  PROJECT_ID = 'ACCESS_PROJECT_ID',
  PROJECT_TYPE = 'ACCESS_PROJECT_TYPE',
  TAB = 'ACCESS_PROJECT_TAB',
}

export interface AccessKeys {
  clientId?: string;
  projectId?: string;
  projectType?: string;
  tab?: string;
}

export enum LocalStorageAccessCheckInKeys {
  NO_SHOW_AGAIN_CHECK_OUT_MODAL = 'ACCESS_CHECK_IN_NO_SHOW_CHECK_OUT',
}

export interface AccessCheckInKeys {
  checked?: boolean;
}

export interface AccessDeviceAssetPayload {
  asset_id: string;
  type: string;
  alias: string | null;
  city: string;
  province: string;
  country: string;
  street_type: string;
  street_name: string;
  block_number: string;
  zip_code: string;
  staircase: string | null;
  flat: string | null;
  door: string | null;
}

export interface AccessDevicePayload {
  asset: AccessDeviceAssetPayload | null;
  name: string | null;
  room: string | null;
  device_type: string | null;
  battery_level: number | null;
  shared: boolean;
  access_sensor_id: string;
}

export interface AccessDeviceMetaPayload {
  total_pages: number;
  page_size: number;
  results: number;
  all_results: number;
}

export interface AccessDeviceLinksPayload {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface AccessDevicesListResponsePayload {
  data: AccessDevicePayload[];
  meta: AccessDeviceMetaPayload;
  links: AccessDeviceLinksPayload;
}

export interface AccessDeviceAssetModel {
  assetId: string;
  type: string;
  alias: string | null;
  city: string;
  province: string;
  country: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  zipCode: string;
  staircase: string | null;
  flat: string | null;
  door: string | null;
}

export interface AccessDeviceModel {
  asset: AccessDeviceAssetModel | null;
  name: string | null;
  room: string | null;
  deviceType: string | null;
  batteryLevel: number | null;
  shared: boolean;
  accessSensorId: string;
}

export interface AccessDeviceMetaModel {
  totalPages: number;
  pageSize: number;
  results: number;
  allResults: number;
}

export interface AccessDeviceLinksModel {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface AccessDevicesListResponseModel {
  data: AccessDeviceModel[];
  meta: AccessDeviceMetaModel;
  links: AccessDeviceLinksModel;
}
