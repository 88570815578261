import { FC } from 'react';
import { useHotelAccessDevicesList } from './resources/useAccessDevicesList';
import AccessDevicesListTemplate from 'components/template/AccessDevicesListTemplate';
import OpenHotelAccessDeviceModal from './resources/OpenHotelAccessDeviceModal';

export const AccessDevicesListTab: FC = () => {
  const {
    accessDevicesList,
    columns,
    pageCurrent,
    pageSize,
    totalPages,
    openAccessDeviceModalOptions,
    onChangePage,
    loadingAccessDevices,
  } = useHotelAccessDevicesList();

  const {
    onCloseAccessDeviceModal,
    onOpenAccessDevice,
    openAccessDeviceModalLiterals,
    openAccessModalVisible,
  } = openAccessDeviceModalOptions

  return (
    <>
      <AccessDevicesListTemplate
        accessDevicesList={accessDevicesList}
        columns={columns}
        page={pageCurrent}
        size={pageSize}
        totalPages={totalPages}
        onChangePage={onChangePage}
        loading={loadingAccessDevices}
      />
      <OpenHotelAccessDeviceModal
        title={openAccessDeviceModalLiterals.title}
        literals={openAccessDeviceModalLiterals}
        onCancel={onCloseAccessDeviceModal}
        onOk={onOpenAccessDevice}
        open={openAccessModalVisible}
      />
    </>
  )
}

export default AccessDevicesListTab;