import { Typography } from 'antd';
import ContextualMenu from 'components/atoms/ContextualMenu';
import MenuAtom from 'components/atoms/MenuAtom';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { AccessDeviceModel } from 'core/domain/access/models';

export interface OfficeAccessDevicesListColumnsLiteralsModel {
  nameTitle: string;
  roomTitle: string;
  areaTitle: string;
  batteryTitle: string;
  actionsTitle: string;
  openAccessDeviceText: string;
}

export interface Props {
  literals: OfficeAccessDevicesListColumnsLiteralsModel,
  onOpenAccessDevice: (accessDevice: AccessDeviceModel) => void;
}

export const OfficeAccessDevicesListColumns = ({ literals, onOpenAccessDevice }: Props): AlfredTableColumn<AccessDeviceModel>[] => {
  const { nameTitle, roomTitle, areaTitle, batteryTitle, actionsTitle, openAccessDeviceText } = literals;

  return [
    {
      title: nameTitle,
      key: 'name',
      width: '30%',
      render: (text, { name }) => <Typography.Text>{!!name ? name : '-'}</Typography.Text>,
    },
    {
      title: roomTitle,
      key: 'room',
      width: '30%',
      render: (text, { room }) => <Typography.Text>{!!room ? room : '-'}</Typography.Text>,
    },
    {
      title: areaTitle,
      key: 'area',
      width: '20%',
      render: (text, { asset }) => <Typography.Text>{!!asset ? asset.alias : '-'}</Typography.Text>,
    },
    {
      title: batteryTitle,
      key: 'battery',
      width: '10%',
      render: (text, { batteryLevel }) => <Typography.Text>{!!batteryLevel ? `${batteryLevel}%` : '-'}</Typography.Text>,
    },
    {
      title: actionsTitle,
      key: 'actions',
      width: '10%',
      render: (text, accessDevice) => <ContextualMenu menu={(
        <MenuAtom
          items={[
            {
              key: 'open-access-device',
              label: openAccessDeviceText,
              onClick: () => onOpenAccessDevice(accessDevice),
            },
          ]}
        />
      )} />,
    },
  ];
};
